import style from "./Services.module.scss";
import SectionHeader from "@components/SectionHeader";
import BannersContainer from "./components/BannersContainer";
import BoxContainer from "@components/BoxContainer";

const SUBTITLE =
  "From the moment you walk into our luxurious reception area, we know you will feel both relaxed and excited, and ready to experience an erotic piece of Heaven. Our girls are the finest you will find anywhere and will ensure you have a truly out of this world experience. And we are conveniently located off Sukhumvit Road (Soi 31), close to restaurants and transport. So come to see us and leave your troubles behind you, if only for a while.";
const TITLE = "Services";

const Services = () => {
  return (
    <section id="services" className={style.servicesContainer}>
      <SectionHeader className="responsive" title={TITLE} subtitle={SUBTITLE} />
      <BoxContainer>
        <BannersContainer />
      </BoxContainer>
    </section>
  );
};

export default Services;
