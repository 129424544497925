import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import {
  getRooms,
  createRoom,
  deleteRoom,
  updateRoom,
} from "services/api/rooms";
import { RoomItem } from "@interfaces/general.interfaces";
import TableRooms from "./components/TableRooms";
import InputForm from "./components/InputForm";
import memoize from "memoize-one";

const Rooms = () => {
  const [rooms, setRooms] = useState<RoomItem[]>([]);
  const [linstenupdate, setListenUpdate] = useState<boolean>(false);
  const [room, setRoom] = useState<RoomItem>({} as RoomItem);

  const roomsFromServer = memoize(async () => {
    const result = await getRooms();
    setRooms(result);
  });


  useEffect(() => {
    roomsFromServer()
  }, [linstenupdate]);


  const handleEditRoom = (room: RoomItem) => {
    setRoom(room);
  };

  const handleUpdateRoom = async (room: RoomItem) => {
    await updateRoom({ _id: room._id, title: room.title, file: room.file, description: room.description });
    setListenUpdate(!linstenupdate);
  };

  const handleDeleteRoom = async (room: RoomItem) => {
    await deleteRoom(room._id);
    setListenUpdate(!linstenupdate);
  };

  const handleAddRoom = async (room: RoomItem) => {
    await createRoom(room);
    setListenUpdate(!linstenupdate);
  };

  return (
    <Grid container spacing={2} alignItems="center" direction="column">
      <InputForm
        onAdd={
          Object.keys(room).length === 0 ? handleAddRoom : handleUpdateRoom
        }
        room={room}
        setRoom={setRoom}
      />
      <TableRooms
        onEditRoom={handleEditRoom}
        onDeleteRoom={handleDeleteRoom}
        rooms={rooms}
      />
    </Grid>
  );
};

export default Rooms;
