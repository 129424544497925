import style from "./Gallery.module.scss";


interface GalleryProps {
  people: any;
}

const Gallery = ({ people }: GalleryProps) => {
  return (
    <div className={style.galleryContainer}>
      {people?.map((person: any) => {
        return (
          <div className={style.itemGallery}>
            <span
              className={style.itemGalleryImage}
              style={{ backgroundImage: `url('${person.file}')` }}
            ></span>
            <div className={style.name}>{person.name}</div>
          </div>
        );
      })}
    </div>
  );
};

export default Gallery;
