import style from "./Gallery.module.scss";
import BoxContainer from "@components/BoxContainer";
import BookBtn from "@components/BookBtn";

interface GalleryProps {
  rooms: any;
}

const Gallery = ({ rooms }: GalleryProps) => {
  return (
    <BoxContainer>
      <div className={style.galleryContainer}>
        {rooms?.map((room: any) => {
          return (
            <div className={style.itemGallery}>
              <span
                className={style.itemGalleryImage}
                style={{ backgroundImage: `url('${room.file}')` }}
              ></span>
              <div className={style.infoContainer}>
                <div className={style.infoTitle}>{room.title}</div>
                <div className={style.infoSubtitle}>{room.description}</div>
                <BookBtn text="Book Now" handleClick={() => {}} />
              </div>
            </div>
          );
        })}
      </div>
    </BoxContainer>
  );
};

export default Gallery;
