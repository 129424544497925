import React, { useState, useEffect } from "react";
import memoize from "memoize-one";
import { Grid } from "@material-ui/core";
import {
  getStaff,
  createStaff,
  deleteStaff,
  updateStaff,
} from "services/api/staff";
import { StaffPerson } from "@interfaces/general.interfaces";
import TableUsers from "./components/TableUsers";
import InputForm from "./components/InputForm";

const Staff = () => {
  const [people, setPeople] = useState<StaffPerson[]>([]);
  const [linstenupdate, setListenUpdate] = useState<boolean>(false);
  const [user, setUser] = useState<StaffPerson>({} as StaffPerson);

  const peopleFromServer = memoize(async () => {
    const result = await getStaff();
    setPeople(result);
  });

  useEffect(() => {
    peopleFromServer();
  }, [linstenupdate]);

  const handleEditPerson = (user: StaffPerson) => {
    setUser(user);
  };
  const handleUpdatePerson = async (user: StaffPerson) => {
    await updateStaff({ _id: user._id, name: user.name, file: user.file });
    setListenUpdate(!linstenupdate);
  };

  const handleDeletePerson = async (user: StaffPerson) => {
    await deleteStaff(user._id);
    setListenUpdate(!linstenupdate);
  };

  const handleAddPerson = async (person: StaffPerson) => {
    await createStaff(person);
    setListenUpdate(!linstenupdate);
  };

  return (
    <Grid container spacing={2} alignItems="center" direction="column">
      <InputForm
        onAdd={
          Object.keys(user).length === 0 ? handleAddPerson : handleUpdatePerson
        }
        user={user}
        setUser={setUser}
      />
      <TableUsers
        onEditUser={handleEditPerson}
        onDeleteUser={handleDeletePerson}
        people={people}
      />
    </Grid>
  );
};

export default Staff;
