import React, { useEffect, useState } from "react";
import style from "./Rooms.module.scss";
import SectionHeader from "@components/SectionHeader";
import Gallery from "./components/Gallery";
import { getRooms } from "services/api/rooms";

const Rooms = () => {
  const [rooms, setRooms] = useState<any>([]);

  useEffect(() => {
    getRooms().then((res) => {
      setRooms(res);
    });
  }, []);

  return (
    <section id="rooms" className={style.roomsContainer}>
      <SectionHeader
        className="responsive"
        css={{ padding: "0 17% 4% 17%" }}
        title="Rooms & Suites"
        subtitle="We have 10 luxury rooms to choose from, all beautifully appointed, from our Deluxe (Shower) Rooms to our exclusive VIP Jacuzzi Rooms"
      />
      <Gallery rooms={rooms} />
    </section>
  );
};

export default Rooms;
