import { CSSProperties } from "react";
import style from "./SectionHeader.module.scss";
import classNames from "classnames";

interface SectionHeaderProps {
  title: string;
  subtitle?: string;
  className?: string;
  css?: CSSProperties
}

const SectionHeader = ({
  title,
  subtitle,
  className = "",
  css
  
}: SectionHeaderProps) => {
  const classes = classNames(style.sectionHeader, style[`${className}`]);

  return (
    <div className={classes} style={{...css}}>
      <h3 className={style.sectionHeaderTitle}>{title}</h3>
      {subtitle && <p className={style.sectionHeaderSubtitle}>{subtitle}</p>}
    </div>
  );
};

export default SectionHeader;
