import React, { useState } from "react";
import style from "./TopBar.module.scss";
import Logo from "@assets/logo.svg";
import BookBtn from "../BookBtn";
import MenuMobile from "./components/MenuMobile";
import Menu from "./components/Menu";

const MENU_ITEMS = [
  {
    text: "Home",
    to: "home",
  },
  {
    text: "Services",
    to: "services",
  },
  {
    text: "Staff",
    to: "staff",
  },
  {
    text: "Rooms",
    to: "rooms",
  },
  {
    text: "Contact",
    to: "contact",
  },
];

const TopBar = () => {
  const handleResize = () => {
    setWidth(window.innerWidth);
  };
  window.addEventListener("resize", handleResize);

  const [width, setWidth] = useState(window.innerWidth);

  return (
    <div className={style.topBarContainer}>
      <div className={style.logoContainer}>
        <img src={Logo} alt="Google Logo" />
      </div>
      {width > 856 ? (
        <>
          <Menu items={MENU_ITEMS} />
          <BookBtn text="Book now" handleClick={() => {}} />
        </>
      ) : (
        <MenuMobile items={MENU_ITEMS} />
      )}
    </div>
  );
};

export default TopBar;
