import React, { useState, useEffect, useRef } from "react";
import style from "./BannerInfoBtn.module.scss";
import classNames from "classnames";
import BookBtn from "../BookBtn";
// import ParallaxImage from "@components/ParralaxImage";

interface BannerInfoBtnProps {
  title: string;
  subtitle?: string;
  className?: string;
  image: boolean | string;
  btnText: string;
  handleBtnClick: () => void;
  styles?: React.CSSProperties;
  delay?: number;
}

const BannerInfoBtn = ({
  title,
  image = false,
  subtitle,
  className = "",
  btnText,
  handleBtnClick,
  styles,
  delay,
}: BannerInfoBtnProps) => {
  const [animate, setAnimate] = useState(false);
  const boxRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
        
      if (!animate && boxRef.current) {
        console.log(boxRef.current)
        const boxPosition = boxRef.current.getBoundingClientRect().top - 100;
        const screenPosition = window.innerHeight / 1.5;
        if (boxPosition < screenPosition) {
          setAnimate(true);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [animate]);

  const classAnimate = animate ? `${style.boxAnimated} ${style.bannerInfoBtn}` : style.bannerInfoBtn;

  const classes = classNames(className, classAnimate);

  return (
    <>
      {/* <ParallaxImage className={classes} imgUrl={image} >
    <h3 className={style.bannerInfoBtnTitle}>{title}</h3>
      {subtitle && <p className={style.bannerInfoBtnSubtitle}>{subtitle}</p>}

      <BookBtn text={btnText} handleClick={handleBtnClick}></BookBtn>
    </ParallaxImage> */}
      <div
        className={classes}
        style={{ backgroundImage: image ? `url(${image})` : "none", ...styles, animationDelay: `${delay}s` }}
        ref={boxRef}
      >
        <h3 className={style.bannerInfoBtnTitle}>{title}</h3>
        {subtitle && <p className={style.bannerInfoBtnSubtitle}>{subtitle}</p>}

        <BookBtn text={btnText} handleClick={handleBtnClick}></BookBtn>
      </div>
    </>
  );
};

export default BannerInfoBtn;
