import style from "./Footer.module.scss";
import LogoColor from "@assets/logoColor.svg";
import Twitter from "@assets/icons/twitter.svg";
import Facebook from "@assets/icons/facebook.svg";
import Instagram from "@assets/icons/instagram.svg";



const Footer = () => {
    return (
    <div>
        <footer className={style.footerContainer}>
            <div className={style.footerContent}>
                <div className={style.footerLogo}>
                    <img alt="logo" src={LogoColor} />
                </div>
                <div className={style.footerText}>
                © Heaven 2019 | all rights reserved
                </div>
                <div className={style.socialNetworks}>
                    <ul>
                        <li><a href="https://www.twitter.com"><img src={Twitter} alt="Twitter"/></a> </li>
                        <li><a href="https://www.facebook.com"><img src={Facebook} alt="Facebook"/></a> </li>
                        <li><a href="https://www.instagram.com"><img src={Instagram} alt="Instagram"/></a> </li>
                    </ul>
                </div>            
            </div>
        </footer>           
    </div>
    );
}

export default Footer;
