import React, { useState, useEffect } from "react";
import style from "./Staff.module.scss";
import StaffImage from "@assets/images/staff.png";
import SectionHeader from "@components/SectionHeader";
import Gallery from "./components/Gallery";
import { getStaff } from "services/api/staff";
import { StaffPerson } from "@interfaces/general.interfaces";


const Staff = () => {

   const [people, setPeople] = useState<StaffPerson[]>([]); 

  const getStaffResult = async () => {
    const response = await getStaff();
   
     setPeople(response)
    };

    useEffect(() => {
        getStaffResult()
    }, [])
  
  return (
    <section id="staff" className={style.staffContainer}>
      <div className={style.staffHeader}>
        <div className={style.imageContainer}>
          <img width={'100%'} height={'auto'} src={StaffImage} alt="staff" />
        </div>
        <SectionHeader
          css={{ paddingTop: "83px"} }
          className="responsive"
          title="Our Staff"
          subtitle="Our girls are all beautiful, talented, and very sensitive to your needs"
        />
      </div>
      <div className={style.staffGallery}>
         <Gallery people={people}/>
      </div>
    </section>
  );
};

export default Staff;
