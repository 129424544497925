import React from "react";
import BackgroundCover from "@components/BackgroundCover";
import styleCss from "./TableRooms.module.scss";
import { RoomItem } from "@interfaces/general.interfaces";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import DeleteIcon from "@mui/icons-material/Delete";

interface Props {
  onEditRoom: (room: RoomItem) => void;
  onDeleteRoom: (room: RoomItem) => void;
  rooms: RoomItem[];
}

const useStyles = makeStyles({
  addButton: {
    backgroundColor: "#CA4145",
    border: "none",
    color: "white",
    padding: "5px",
    fontSize: "16px",
    cursor: "pointer",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    transition: "all 0.2s ease-in-out",
    margin: "5px",
    "&:hover": {
      backgroundColor: "white",
      color: "#CA4145",
      boxShadow: "0px 8px 8px rgba(0, 0, 0, 0.25)",
      transform: "translateY(-2px)",
    },
  },
});

const TableRooms: React.FC<Props> = ({ onEditRoom, onDeleteRoom, rooms }) => {
  const style = useStyles();

  return (
    <table className={styleCss["table-users"]}>
      <thead>
        <tr>
          <th>Image</th>
          <th>Title</th>
          <th>Description</th>
          <th>Options</th>
        </tr>
      </thead>
      <tbody>
        {rooms?.map((room) => (
          <tr key={room.title}>
            <td>
              <BackgroundCover
                imageUrl={room.file}
                width="320px"
                height="235px"
                restStyle={{ display: "inline-block", margin: "0 auto" }}
              />
            </td>
            <td>{room.title}</td>
            <td>{room.description}</td>
            <td>
              <Button
                className={style.addButton}
                onClick={() => onEditRoom(room)}
              >
                <ModeEditOutlineIcon />{" "}
              </Button>
            
              <Button
                className={style.addButton}
                onClick={() => onDeleteRoom(room)}
              >
                <DeleteIcon />{" "}
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TableRooms;
