import { Link } from "react-scroll";
import style from "./Menu.module.scss";

interface MenuProps {
  items: Array<{
    text: string;
    to: string;
  }>;
}

const Menu = ({ items }: MenuProps) => {
  return (
    <div className={style.menuContainer}>
    <ul>
      {items.map((item, index) => (
        <li key={index}>
          <Link activeClass={style.active} to={item.to} spy={true} smooth={true}>
             {item.text}
          </Link>
        </li>
      ))}
    </ul>
    </div>
  );
};

export default Menu;
