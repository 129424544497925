import style from "./ContactUs.module.scss";
import SectionHeader from "@components/SectionHeader";
import InfoForm from "./components/InfoForm";
import Form from "./components/Form";

const ContactUs = () => {
  return (
    <section id="contact" className={style.contactUsContainer}>
      <SectionHeader
        title="Contact Us"
        subtitle="We are located on Sukhumvit Soi 31, just a few minutes' walk from emQuartier and Phrom Phong BTS station"
        css={{ width: "70%" }}
     />
     
      <div className={style.formContainer}>
        <InfoForm />
        <Form />
      </div>
    </section>
  );
};

export default ContactUs;
