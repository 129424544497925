import style from "./InfoForm.module.scss";
import Phone from "@assets/icons/phone.svg";
import Email from "@assets/icons/email.svg";
import Location from "@assets/icons/location.svg";

const InfoForm = () => {
    return (
        <div className={style.contactUsFormContainer}>
            <div className={style.contactUsInfo}>
                <div className={style.infoItem}>
                    <span className={style.icon}><img alt="phone" src={Phone} /></span>
                    <span className={style.iconText}>
                        <div className={style.iconTitle}>Phone</div>
                        <div className={style.iconDescription}><a href='tel:0632175693'>0632175693</a></div>
                    </span>
                </div>

                <div className={style.infoItem}>
                    <span className={style.icon}><img alt="email" src={Email} /></span>
                    <span className={style.iconText}>
                        <div className={style.iconTitle}>Email</div>
                        <div className={style.iconDescription}>admin@heavensuites.com</div>
                    </span>
                </div>

                <div className={style.infoItem}>
                    <span className={style.icon}><img alt="location" src={Location} /></span>
                    <span className={style.iconText}>
                        <div className={style.iconTitle}>Address</div>
                        <div className={style.iconDescription}>3/19 Soi Sukhumvit 31, Sukhumvit Road, Klongtoey, Bangkok 10110 Thailand.</div>
                    </span>
                </div>
            </div>
        </div>
    );
};


export default InfoForm;