import React from "react";
import BackgroundCover from "@components/BackgroundCover";
import styleCss from "./TableUsers.module.scss";
import { StaffPerson } from "@interfaces/general.interfaces";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import DeleteIcon from "@mui/icons-material/Delete";

interface Props {
  onEditUser: (user: StaffPerson) => void;
  onDeleteUser: (user: StaffPerson) => void;
  people: StaffPerson[];
}

const useStyles = makeStyles({
  addButton: {
    backgroundColor: "#CA4145",
    border: "none",
    color: "white",
    padding: "5px",
    fontSize: "16px",
    cursor: "pointer",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    transition: "all 0.2s ease-in-out",
    margin: "5px",
    "&:hover": {
      backgroundColor: "white",
      color: "#CA4145",
      boxShadow: "0px 8px 8px rgba(0, 0, 0, 0.25)",
      transform: "translateY(-2px)",
    },
  },
});

const TableUsers: React.FC<Props> = ({ onEditUser, onDeleteUser, people }) => {
  const style = useStyles();

  return (
    <table className={styleCss["table-users"]} style={{ border: "0" }}>
      <thead>
        <tr>
          <th>Image</th>
          <th>Name</th>
          <th>Options</th>
        </tr>
      </thead>
      <tbody>
        {people?.map((user) => (
          <tr key={user.name}>
            <td>
              <BackgroundCover
                imageUrl={user.file}
                width="130px"
                height="235.5px"
                restStyle={{ display: "inline-block", margin: "0 auto" }}
              />
            </td>
            <td>{user.name}</td>
            <td>
              <Button
                className={style.addButton}
                onClick={() => onEditUser(user)}
              >
                <ModeEditOutlineIcon />{" "}
              </Button>
              <br></br>
              <Button
                className={style.addButton}
                onClick={() => onDeleteUser(user)}
              >
                <DeleteIcon />{" "}
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TableUsers;
