import { customAxios } from "../axios";
import { RoomItem } from "@interfaces/general.interfaces";

export const getRooms = async () => {
  const response = await customAxios("application/json").get("/rooms", {});
  return response.data;
};

export const deleteRoom = async (id: string) => {
  const response = await customAxios("application/json").delete(`/rooms/${id}`);
  return response.data;
};

export const createRoom = async ({ title, file, description }: RoomItem) => {
  const newRoom = await customAxios("application/json").post(`/rooms`, {
    title,
    description
  });

  if (file) {
    const formData = new FormData();
    formData.append("file", file);
    const response = await customAxios("multipart/form-data").put(
      `/rooms/${newRoom.data._id}/image`,
      formData
    );
    return response.data;
  }
  return newRoom.data;
};

export const updateRoom = async ({ _id, title, description, file }: RoomItem) => {
  
  const response = await customAxios("application/json").put(`/rooms/${_id}`, {
    title,
    description
  });

  if (file) {
    const formData = new FormData();
    formData.append("file", file);
    await customAxios("multipart/form-data").put(
      `/rooms/${_id}/image`,
      formData
    );
  }
  return response.data;
};
