import React from "react";
import style from "./Top.module.scss";
import BookBtn from "@components/BookBtn";

const Top = () => {
  return (
    
    <section id="home" className={style.bannerContainer}>
      <div className={style.bannerTextContainer}>
        <h1 className={style.bannerTitle}>
          <span>Welcome to Heaven</span> Nuru Massage  <br /> Your Gateway to Sensual Bliss !
        </h1>
        <p className={style.bannerText}>
          We're dedicated to providing you with an unforgettable experience of pure relaxtion and indulgence
        </p>
        <BookBtn text= "Book now" handleClick={() => {}}/>
      </div>
    </section>
  );
};

export default Top;
