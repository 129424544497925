import React from "react";
import style from "./BookBtn.module.scss";
import classNames from "classnames";

interface BookBtnProps {
  text: string;
  handleClick: () => void;
  css?: React.CSSProperties;
  className?: string;
}

const BookBtn = ({ text, handleClick, css, className }: BookBtnProps) => {

    const classes = classNames(style.bookNowBtn, style[`${className}`]);

  return (
    <div style={{ ...css }} className={classes} onClick={handleClick}>
      {text}
    </div>
  );
};

export default BookBtn;
