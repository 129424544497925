import React, {useState} from "react";
import { slide as Menu } from "react-burger-menu";
import MenuIcon from "@assets/menu.svg";
import CrossIcon from "@assets/cross.svg";
import { Link } from "react-scroll";
import style from "./MenuMobile.module.scss";

interface MenuMobileProps {
  items: Array<{
    text: string;
    to: string;
  }>;
}

const MenuMobile = ({ items }: MenuMobileProps) => {

    const [open, setOpen] = useState(false);
    const handleStateChange = (state: any) => {
        setOpen(state.isOpen);
    };

    const handleClose = (state: any) => {
        setOpen(state);
    };

  return (
    <div className={style.menuContainer}>
    <Menu
      isOpen={open}
      onStateChange={(state:any) => handleStateChange(state)}
      itemListElement="nav"
      overlayClassName={style.overlay}
      burgerButtonClassName={style.burgerButton}
      crossButtonClassName={style.crossButton}
      itemListClassName={style.itemList} 
      menuClassName={ style.menu }
      menuWrapClassName={ style.menuWrap }
      customCrossIcon={<img alt="close" src={CrossIcon} />}
      customBurgerIcon={<img alt="open" src={MenuIcon} />}
      left
      className={style.menuMobile}
    >   
        
      {items.map((item, index) => (
       
        <Link onClick={() => {handleClose(!open)}} className={style.itemList} key={index} activeClass={style.active} to={item.to} spy={true} smooth={true}>
          {item.text}
        </Link>
      
      ))}
      
    </Menu>
    </div>
  );
};

export default MenuMobile;
