import React, { useState, useEffect, useRef } from "react";
import { TextField, Button, Grid } from "@material-ui/core";
import BackgroundCover from "@components/BackgroundCover";
import styleCss from "./InputForm.module.scss";
import { makeStyles } from "@material-ui/core/styles";
import { StaffPerson } from "@interfaces/general.interfaces";
import FileUploadIcon from "@mui/icons-material/FileUpload";

interface AddStaffPersonProps {
  onAdd: (person: StaffPerson) => void;
  user: StaffPerson;
  setUser: (user: StaffPerson) => void;
}

const useStyles = makeStyles({
  avatar: {
    backgroundColor: "transparent",
    width: 100,
    height: 100,
    border: "1px solid white",
  },
  textField: {
    "& .MuiInputBase-input": {
      color: "black",
    },
    "& .MuiInputBase-input::placeholder": {
      color: "black",
      opacity: 0.6,
    },
    "& .MuiInputLabel-root": {
      color: "black",
    },

    "& .MuiInput-underline:before": {
      borderBottomColor: "black",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "black",
    },
  },
  addButton: {
    backgroundColor: "#CA4145",
    border: "none",
    color: "white",
    padding: "5px",
    fontSize: "16px",
    cursor: "pointer",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    transition: "all 0.2s ease-in-out",
    margin: "5px",
    "&:hover": {
      backgroundColor: "#CA1145",
      color: "#ffffff",
      boxShadow: "0px 8px 8px rgba(0, 0, 0, 0.25)",
      transform: "translateY(-2px)",
    },
  },

  uploadBtn: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#CA4145",
    border: "none",
    color: "#ffffff",
    fontSize: "16px",
    cursor: "pointer",
    borderRadius: "5px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    transition: "all 0.2s ease-in-out",
    margin: "15px",
    "&:hover": {
      backgroundColor: "#CA1145",
      boxShadow: "0px 8px 8px rgba(0, 0, 0, 0.25)",
      transform: "translateY(-2px)",
    },
  },

  uploadFileBtn: {
    display: "none",
  },
});

interface UserInput {
  name: string;
  pictureUrl: string;
  pictureFile: File | null;
  userid: string;
}

const InputForm: React.FC<AddStaffPersonProps> = ({ onAdd, user, setUser }) => {
  const [userinput, setUserinput] = useState<UserInput>({
    name: "",
    pictureUrl: "",
    pictureFile: null,
    userid: "",
  });

  const style = useStyles();
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (Object.keys(user).length > 0) {
      fetch(user.file)
        .then((response) => response.blob())
        .then((blob) => {
          const filename = user.file.substring(user.file.lastIndexOf("/") + 1);
          const file = new File([blob], filename, { type: blob.type });
          setUserinput((prev) => ({
            ...prev,
            name: user.name,
            pictureUrl: user.file,
            userid: user._id,
            pictureFile: file,
          }));
        })
        .catch((error) => {
          console.error("Error fetching file:", error);
        });
    }
  }, [user]);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserinput((prev) => ({
      ...prev,
      name: event.target.value,
    }));
  };

  const handlePictureChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setUserinput((prev) => ({
          ...prev,
          pictureUrl: reader.result as string,
          pictureFile: file,
        }));
      };
    }
  };

  const handleAddClick = () => {
    if (userinput.pictureFile) {
      const payload: StaffPerson = {
        _id: userinput.userid,
        name: userinput.name,
        file: userinput.pictureFile,
      };
      onAdd(payload);
      setUser({} as StaffPerson);
      setUserinput((prev) => ({
        ...prev,
        name: "",
        pictureUrl: "",
        userid: "",
        pictureFile: null,
      }));
    }

    if (fileInputRef.current) fileInputRef.current.value = "";
  };

  const handleCancelClick = () => {
    setUserinput((prev) => ({
      ...prev,
      name: "",
      pictureUrl: "",
      userid: "",
      pictureFile: null,
    }));

    setUser({} as StaffPerson);
    if (fileInputRef.current) fileInputRef.current.value = "";
  };

  return (
    <div className={styleCss.formContainer}>
      <Grid item>
        {userinput.pictureUrl && (
          <Grid item xs>
            <BackgroundCover
              imageUrl={userinput.pictureUrl}
              width="130px"
              height="235.5px"
              restStyle={{ display: "inline-block", margin: "0 auto" }}
            />
          </Grid>
        )}
      </Grid>
      <Grid item xs>
        <TextField
          label="Name"
          value={userinput.name}
          onChange={handleNameChange}
          fullWidth
          className={style.textField}
        />
      </Grid>
      <Grid item xs>
        <input
          type="file"
          accept="image/*"
          onChange={handlePictureChange}
          className={styleCss.fileUploadButton}
          id="cover-image-upload"
          ref={fileInputRef}
        />

        <Button className={style.uploadBtn}>
          <label htmlFor="cover-image-upload" className={styleCss.label}>
            <span> Upload image</span>
            <FileUploadIcon />
          </label>
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          onClick={(event) => handleAddClick()}
          className={style.addButton}
        >
          Add
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={(event) => handleCancelClick()}
          className={style.addButton}
        >
          Cancel
        </Button>
      </Grid>
    </div>
  );
};

export default InputForm;
