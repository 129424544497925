import React, { useState } from "react";
import style from "./Form.module.scss";
import BookBtn from "@components/BookBtn";
import { IEmail } from "@interfaces/general.interfaces";
import { sendEmail } from "services/api/email";
import { dispatchEvent } from "@hooks/useEvent";

const Form = () => {
  const [email, setEmail] = useState<IEmail>({
    name: "",
    email: "",
    message: "",
  });

  const [loading, setLoading] = useState<boolean>(false);

  const handleEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEmail((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const validateEmail = (email: string): boolean => {
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return regex.test(email);
  };

  const handleSubmit = async () => {
    setLoading(true)
    if (email.name === "" || email.email === "" || email.message === "") {
      const payload = {
        severity: "error",
        message: "Please fill all the fields",
        open: true,
      };
      dispatchEvent({ eventName: "snackbar", payload });
      setLoading(false)
      return;
    }

    if (!validateEmail(email.email)) {
      const payload = {
        severity: "error",
        message: "Please enter a valid email",
        open: true,
      };
      dispatchEvent({ eventName: "snackbar", payload });
      setLoading(false)
      return;
    }

    const data = await sendEmail(email);
    const payload = {
      severity: data.status,
      message: data.message,
      open: true,
    };
    dispatchEvent({ eventName: "snackbar", payload });
    setLoading(false)
  };

  return (
    <div className={style.contactForm}>
      <form>
        <div className={style.inputItem}>
          <label htmlFor="name">Name</label>
          <input
            onChange={(e) => handleEmail(e)}
            placeholder="Enter your full name"
            type="text"
            name="name"
            id="name"
          />
        </div>
        <div className={style.inputItem}>
          <label htmlFor="name">Email</label>
          <input
            onChange={(e) => handleEmail(e)}
            placeholder="loremipsum@gmail.com"
            type="text"
            name="email"
            id="email"
          />
        </div>
        <div className={style.inputItem}>
          <label htmlFor="name">Message</label>
          <input
            onChange={(e) => handleEmail(e)}
            placeholder="Enter your message here"
            type="text"
            name="message"
            id="message"
          />
        </div>
        <BookBtn
          className={"responsive"}
          text={loading ? "Sending..." : "Send Message"}
          handleClick={handleSubmit}
        />
      </form>
    </div>
  );
};

export default Form;
