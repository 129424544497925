import React, { useState, useEffect, useRef } from "react";
import { TextField, Button, Grid } from "@material-ui/core";
import BackgroundCover from "@components/BackgroundCover";
import styleCss from "./InputForm.module.scss";
import { makeStyles } from "@material-ui/core/styles";
import { RoomItem } from "@interfaces/general.interfaces";
import FileUploadIcon from "@mui/icons-material/FileUpload";

interface AddRoomsItemProps {
  onAdd: (person: RoomItem) => void;
  room: RoomItem;
  setRoom: (user: RoomItem) => void;
}

const useStyles = makeStyles({
    avatar: {
        backgroundColor: "transparent",
        width: 100,
        height: 100,
        border: "1px solid white",
      },
      textField: {
        "& .MuiInputBase-input": {
          color: "black",
        },
        "& .MuiInputBase-input::placeholder": {
          color: "black",
          opacity: 0.6,
        },
        "& .MuiInputLabel-root": {
          color: "black",
        },
    
        "& .MuiInput-underline:before": {
          borderBottomColor: "black",
        },
        "& .MuiInput-underline:after": {
          borderBottomColor: "black",
        },
      },
      addButton: {
        backgroundColor: "#CA4145",
        border: "none",
        color: "white",
        padding: "5px",
        fontSize: "16px",
        cursor: "pointer",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        transition: "all 0.2s ease-in-out",
        margin: "5px",
        "&:hover": {
          backgroundColor: "#CA1145",
          color: "#ffffff",
          boxShadow: "0px 8px 8px rgba(0, 0, 0, 0.25)",
          transform: "translateY(-2px)",
        },
      },
    
      uploadBtn: {
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#CA4145",
        border: "none",
        color: "#ffffff",
        fontSize: "16px",
        cursor: "pointer",
        borderRadius: "5px",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        transition: "all 0.2s ease-in-out",
        margin: "15px",
        "&:hover": {
          backgroundColor: "#CA1145",
          boxShadow: "0px 8px 8px rgba(0, 0, 0, 0.25)",
          transform: "translateY(-2px)",
        },
      },
    
      uploadFileBtn: {
        display: "none",
      },
});

interface RoomInput {
  title: string;
  description: string;
  pictureUrl: string;
  pictureFile: File | null;
  roomid: string;
}

const InputForm: React.FC<AddRoomsItemProps> = ({ onAdd, room, setRoom }) => {
  const [roominput, setRoomInput] = useState<RoomInput>({
    title: "",
    description: "",
    pictureUrl: "",
    pictureFile: null,
    roomid: "",
  });

  const style = useStyles();
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (Object.keys(room).length > 0) {
      fetch(room.file)
        .then((response) => response.blob())
        .then((blob) => {
          const filename = room.file.substring(room.file.lastIndexOf("/") + 1);
          const file = new File([blob], filename, { type: blob.type });
          setRoomInput((prev) => ({
            ...prev,
            title: room.title,
            description: room.description,
            pictureUrl: room.file,
            roomid: room._id,
            pictureFile: file,
          }));
        })
        .catch((error) => {
          console.error("Error fetching file:", error);
        });
    }
  }, [room]);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRoomInput((prev) => ({
      ...prev,
      title: event.target.value,
    }));
  };

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRoomInput((prev) => ({
      ...prev,
      description: event.target.value,
    }));
  };

  const handlePictureChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setRoomInput((prev) => ({
          ...prev,
          pictureUrl: reader.result as string,
          pictureFile: file,
        }));
      };
    }
  };

  const handleAddClick = () => {
    if (roominput.pictureFile) {
      const payload: RoomItem = {
        _id: roominput.roomid,
        title: roominput.title,
        description: roominput.description,
        file: roominput.pictureFile,
      };
      onAdd(payload);
      setRoom({} as RoomItem);
      setRoomInput((prev) => ({
        ...prev,
        title: "",
        pictureUrl: "",
        description: "",
        roomid: "",
        pictureFile: null,
      }));
    }

    if (fileInputRef.current) fileInputRef.current.value = "";
  };

  const handleCancelClick = () => {
    setRoomInput((prev) => ({
      ...prev,
      title: "",
      pictureUrl: "",
      description: "",
      roomid: "",
      pictureFile: null,
    }));

    setRoom({} as RoomItem);
    if (fileInputRef.current) fileInputRef.current.value = "";
  };

  return (
    <div className={styleCss.formContainer}>
      <Grid item>
        {roominput.pictureUrl && (
          <Grid item xs>
            <BackgroundCover
              imageUrl={roominput.pictureUrl}
              width="320px"
              height="235px"
              restStyle={{ display: "inline-block", margin: "0 auto" }}
            />
          </Grid>
        )}
      </Grid>
      <Grid item xs>
        <TextField
          label="Title"
          value={roominput.title}
          onChange={handleNameChange}
          fullWidth
          className={style.textField}
        />
      </Grid>
      <Grid item xs>
        <TextField
          label="Description"
          value={roominput.description}
          onChange={handleDescriptionChange}
          fullWidth
          className={style.textField}
        />
      </Grid>
      <Grid item xs>
        <input
          type="file"
          accept="image/*"
          onChange={handlePictureChange}
          className={styleCss.fileUploadButton}
          id="cover-image-upload"
          ref={fileInputRef}
        />

        <Button className={style.uploadBtn}>
          <label htmlFor="cover-image-upload" className={styleCss.label}>
            <span> Upload image</span>
            <FileUploadIcon />
          </label>
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          onClick={(event) => handleAddClick()}
          className={style.addButton}
        >
          Send
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={(event) => handleCancelClick()}
          className={style.addButton}
        >
          Cancel
        </Button>
      </Grid>
    </div>
  );
};

export default InputForm;
