import React from 'react';
import TopBar from '@components/TopBar';
import Top from './components/Top';
import Services from './components/Services';
import Staff from './components/Staff';
import Rooms from './components/Rooms';
import ContactUs from './components/ContactUs';
import Footer from '@components/Footer';

const Home = () => {
    return (
        <div id='homePage'>
            <TopBar />
            <Top />
            <Services />
            <Staff />
            <Rooms />
            <ContactUs />
            <Footer />
        </div>
    );
}

export default Home;