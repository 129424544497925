import React, { useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from "@material-ui/core";
import { People, Home, Settings } from "@material-ui/icons";
import { Outlet, NavLink } from "react-router-dom";
import LogoColor from "@assets/logoColor.svg";
import classes from "./Main.module.scss";
import useSession from "@hooks/useSession";
import { ISessionContext } from "@interfaces/general.interfaces";

const Main: React.FC = () => {
  const [activeMenu, setActiveMenu] = useState("home");

  const handleMenuClick = (menu: string) => {
    setActiveMenu(menu);
  };

  const { logout } = useSession() as ISessionContext;

  return (
    <div className={classes.root}>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar />
        <Toolbar className={classes.appBar}>
          <NavLink to="/">
            <img src={LogoColor} alt="Logo" />
          </NavLink>
        </Toolbar>
        <div className={classes.drawerContainer}>
          <List className={classes.ulList}>
            
              <ListItem
                button
                selected={activeMenu === "home"}
                onClick={() => handleMenuClick("home")}
              >
                <NavLink to="/staff" className={classes.links}>
                <ListItemIcon>
                  <People />
                </ListItemIcon>
                <ListItemText primary="Staff" />
                </NavLink>
              </ListItem>
            
            <div className={` ${classes.penultimate}` }>
              <ListItem
                button
                selected={activeMenu === "dashboard"}
                onClick={() => handleMenuClick("dashboard")}
                
                >
                <NavLink to="/rooms" className={` ${classes.links}` } >
                <ListItemIcon>
                  <Home />
                </ListItemIcon>
                <ListItemText primary="Rooms" />
                </NavLink>
              </ListItem>
            </div>
         

            <ListItem
              button
              selected={activeMenu === "settings"}
              onClick={() => logout()}
              className={` ${classes.links} ${classes.logout}` }
            >
              <ListItemIcon>
                <Settings />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
        </div>
      </Drawer>
      <main className={classes.content}>
        <Toolbar />
        <Outlet />
      </main>
    </div>
  );
};

export default Main;
