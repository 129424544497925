import style from "./BannersContainer.module.scss";
import BannerInfoBtn from "@components/BannerInfoBtn";
import Service1 from "@assets/images/service1.svg";
import Service2 from "@assets/images/service2.svg";
import Service3 from "@assets/images/service3.svg";
import { animateScroll, scroller } from 'react-scroll';

const BannersContainer = () => {

    const handleClick = (target: string) => {
        if (document.getElementById(target)) {
          scroller.scrollTo(target, {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart'
          });
        } else {
          animateScroll.scrollToTop({
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart'
          });
        }
      };


  return (
    <div className={style.bannersContainer}>
      <BannerInfoBtn
        title="Heaven Massage"
        image={Service1}
        subtitle="Some people still don't understand what Massage is. Let me explain this wonderful experience for you. A Massage is an erotic massage that originated in Japan. The masseuse uses her entire body to massage you, providing much more bodily contact than found in a traditional massage.And it is much more exciting too. Our shop has specially trained girls to give you a once in a lifetime experience. So, what can expect from our Massage girls?"
        btnText="Contact us"
        handleBtnClick={() => {handleClick('contact')}}
        delay={0.5}
      />
      <BannerInfoBtn
        title="Our girls"
        image={Service2}
        subtitle="Our girls are all beautiful, talented, and very sensitive to your needs. We carefully select each girl and train them to provide exceptional service for you with a smile. You will never be disappointed. We call ourselves Heaven because your girl will take you on an unforgettable wet and exciting ride that will leave you breathless but wanting more. Your complete satisfaction is our only concern. Each of our Heavenly girls are young, lovely, clean, and focused entirely on you to satisfy your heart's desires."
        btnText="Contact us"
        handleBtnClick={() => {handleClick('contact')}}
        delay={1}
      />
      <BannerInfoBtn
        title="Clean, new, modern rooms"
        image={Service3}
        subtitle="We have 10 luxury rooms to choose from, all beautifully appointed, from our Deluxe (Shower) Rooms to our exclusive VIP Jacuzzi Rooms. So why not pamper yourself and experience all that Heaven Nuru Massage Bangkok can offer. All of Heaven rooms are stylish, unique, and comfortable. We have attended to every small detail in our rooms so that you will truly feel like you are in Heaven. To really take advantage of this wonderful NURU massage in Bangkok experience, you should try our VIP rooms. We promise you an experience you will never forget, but we know you will come back for more."
        btnText="Contact us"
        handleBtnClick={() => {handleClick('contact')}}
        delay={1.5}
      />
    </div>
  );
};

export default BannersContainer;
