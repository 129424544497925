import { customAxios } from "../axios";
import { StaffPerson } from "@interfaces/general.interfaces";

export const getStaff = async () => {
  const response = await customAxios("application/json").get("/staff", {});
  return response.data;
};

export const deleteStaff = async (id: string) => {
  const response = await customAxios("application/json").delete(`/staff/${id}`);
  return response.data;
};

export const createStaff = async ({ name, file }: StaffPerson) => {
  const newStaff = await customAxios("application/json").post(`/staff`, {
    name,
  });

  if (file) {
    const formData = new FormData();
    formData.append("file", file);
    const response = await customAxios("multipart/form-data").put(
      `/staff/${newStaff.data._id}/image`,
      formData
    );
    return response.data;
  }
  return newStaff.data;
};

export const updateStaff = async ({ _id, name, file }: StaffPerson) => {
 
  const response = await customAxios("application/json").put(`/staff/${_id}`, {
    name,
  });

  if (file) {
    const formData = new FormData();
    formData.append("file", file);
    await customAxios("multipart/form-data").put(
      `/staff/${_id}/image`,
      formData
    );
  }
  return response.data;
};
